import React, { useState } from "react";
import "../Pages/css/Help.css";
import axios from "axios";
import { IoMdMail } from "react-icons/io";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaMapLocationDot } from "react-icons/fa6";

const Help = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      Name: name,
      Email: email,
      Message: message,
    };
    axios
      .post(
        "https://sheet.best/api/sheets/e64d31f2-2665-4730-ba60-d74a0eaeb14e",
        data
      )
      .then((response) => {
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        alert(
          "Mesajınız gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin."
        );
      });
  };

  const handleEmailRedirect = () => {
    window.location.href = "mailto:ornek@ornek.com";
  };

  return (
    <div className="help-page-body">
      <div className="container-main-help">
        <div className="container-help">
          <div className="text">GET IN TOUCH</div>
          <div className="divider"></div>
        </div>
        <p>
          You can contact us through many sources. We’d like to hear any
          thoughts, demands or complaints of yours. We’d be pleased to listening
          to you and to helping you!
        </p>
        <div className="container-contact">
          <div className="contact-form">
            <h2>LEAVE US A MESSAGE</h2>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Name*"
                />
              </div>
              <div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email*"
                />
              </div>
              <div>
                <textarea
                  id="message"
                  name="message"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  placeholder="Message*"
                />
              </div>
              <button type="submit" className="btn-clash one">
                <div class="overlay"></div>
                <div class="overlay"></div>
                <div class="overlay"></div>
                <span>SEND</span>
              </button>
            </form>
          </div>
          <div className="contact-info">
            <h2>OUR DETAILS</h2>
            <div className="contact-info-card">
              <IoMdMail className="contact-info-card-icon" />
              <p>Email:</p>
              <span onClick={handleEmailRedirect}>premadesoft@gmail.com</span>
            </div>

            <div className="contact-info-card">
              <AiOutlineGlobal className="contact-info-card-icon" />
              <p>Website:</p>
              <span>www.hackerword.com</span>
            </div>

            <div className="contact-info-card">
              <FaMapLocationDot className="contact-info-card-icon" />
              <p>Address:</p>
              <span>Izmir, Turkiye</span>
            </div>
            <iframe
              width="500px"
              height="330"
              loading="lazy"
              allowFullScreen
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237850.0363530685!2d27.043564713823653!3d38.438854837044815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd862a762cacd%3A0x628cbba1a59ce8fe!2zxLB6bWly!5e0!3m2!1str!2str!4v1713454510661!5m2!1str!2str"
              title="İzmir Haritası"
              className="contact-map"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
