import React from "react";
import "../Card/BlogCard.css";
import BruteForceAttack from "../Card/assets/brute-force-attack.png";
import Card2 from "../Card/assets/card-2.png";
import Trojan from "../Card/assets/Typosquatting-img.png";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

const BlogCard = () => {
  return (
    <>
      <main>
        <section class="cards">
          <div class="card">
            <Link
              to={"/blog/brute-force-attack/"}
              style={{ textDecoration: "none" }}
            >
              <div class="card__image-container">
                <img src={BruteForceAttack} alt="Resim" />
              </div>
              <div class="card__content">
                <p class="card__title text--medium">Brute force attack</p>
                <div class="card__info">
                  <p class="text--medium">What is a brute force attack ?</p>
                </div>
              </div>
            </Link>
          </div>

          <div class="card">
            <Link
              to={"/blog/man-in-the-browser-attack/"}
              style={{ textDecoration: "none" }}
            >
              <div class="card__image-container">
                <img
                  src={Card2}
                  alt="Resim"
                  style={{
                    height: "130px",
                  }}
                />
              </div>
              <div class="card__content">
                <p class="card__title text--medium">
                  Man-in-the-Browser (MitB) Attack
                </p>
                <div class="card__info">
                  <p class="text--medium">
                    What is a Man-in-the-Browser (MitB) Attack ?
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div class="card">
            <Link to={"/blog/typosquatting/"}
            style={{ textDecoration: "none" }}>
              <div class="card__image-container">
                <img src={Trojan} alt="Resim" />
              </div>
              <div class="card__content">
                <p class="card__title text--medium">Typosquatting</p>
                <div class="card__info">
                  <p class="text--medium">What is a Typosquatting ?</p>
                </div>
              </div>
            </Link>
          </div>
        </section>
      </main>
    </>
  );
};

export default BlogCard;
