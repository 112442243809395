import React from "react";
import "../Pages/css/Advertisements.css";
import { FaInstagram } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <>
      <div className="advertisements-page-body">
        <div className="HW-SUBHEADING">
          <h1>Term of Use</h1>
        </div>

        <div className="ads-cont-main">
          <h1>Hacker Simulator Terms of Use</h1>

          <p>
            When using Hacker Simulator, you agree to abide by the following
            terms of use:
          </p>

          <h1>1. Right of Use and License</h1>
          <p>
            You do not need to pay any fees to use Hacker Simulator. The game is
            offered free of charge and is accessible to everyone. However, all
            rights to the game are reserved.
          </p>

          <h1>2. Use of the Game</h1>
          <p>
            Hacker Simulator is for personal and entertainment use only. Use for
            commercial or illegal activities is strictly prohibited.
          </p>

          <h1>3. Usage Restrictions</h1>
          <p>
            The following actions are strictly prohibited when using Hacker
            Simulator: - Copying, distributing or modifying the game. -
            Circumventing or manipulating the game's security measures. -
            In-game cheating or fraud attempts. - Misuse of the game or
            interfering with the experience of others.
          </p>

          <h1>4. Disclaimer of Liability</h1>
          <p>
            When you use Hacker Simulator, you will not be liable for any loss
            or damage arising from possible errors or interruptions of the game.
            You use the game at your own risk.
          </p>

          <h1>5. Policy Changes</h1>
          <p>
            Hacker Simulator's terms of use may be updated from time to time. We
            recommend that you check this page regularly to accept the updated
            terms. These terms of use set out the rules and conditions you must
            follow when using Hacker Simulator. By using the game, you agree to
            these terms.
          </p>

          <p>
            These terms of use clearly state the rules and restrictions that
            users must follow when using the game, despite the fact that the
            game is offered free of charge.
          </p>
        </div>
        <div className="local-footer-main">
          <div className="local-footer-tools">
            <h1>More</h1>
            <div className="local-footer-icons-container">
              <Link to={"/help"}>
                <FaRegCommentDots className="local-footer-tools-icon" />
              </Link>
              <Link
                to={"/https://www.instagram.com/premadesoft"}
                target="_blank"
              >
                <FaInstagram className="local-footer-tools-icon" />
              </Link>
            </div>
          </div>
          <div className="local-footer-text">
            <Link
              to={"/privacy-policy"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>Privacy Policy</p>
            </Link>

            <Link
              to={"/terms-of-use"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>Terms of Use</p>
            </Link>

            <Link
              to={"/about-our-ads"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>About our Ads</p>
            </Link>
            <p></p>
            <p>
              <span>©</span> 2024 Premadesoft
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
