import React from "react";
import "../BlogSection/BlogSection.css";
import { MdArrowOutward } from "react-icons/md";
import BlogCard from "./Card/BlogCard";
import Logo from "../BlogSection/favicon.ico";
import { SlArrowRight } from "react-icons/sl";
import { Link } from "react-router-dom";

const BlogSection = () => {
  return (
    <>
      <div className="section-head">
        <h1>MOST CURRENT BLOGS</h1>
        <Link
          to={"/blogs"}
          style={{
            textDecoration: "none",
          }}
        >
          <div className="section-sub-container">
            <p>See All Blogs</p>
            <MdArrowOutward className="section-icon" />
          </div>
        </Link>
      </div>
      <BlogCard />

      <div className="how-to-play-container">
        <img src={Logo} className="logo" />
        <div className="how-to-play-text-container">
          <h1>How to play ?</h1>
          <p>
            When users start using the Hacker Simulator, they can automatically
            generate hacker-style text by pressing random keys on the keyboard.
            In the settings section, they can choose their desired colors and
            customize the font size. Additionally, the simulator offers many
            features such as opening hacker windows.
          </p>
        </div>
        <button className="button-test">
          Play Guide
          <SlArrowRight />
        </button>
      </div>
    </>
  );
};

export default BlogSection;
