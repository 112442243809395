export const metinler = [
'penetrate" == typeof $.accessRequest &&',
'($.accessRqst = 10),',
'function(a, b, c) {',
'function d(c) {',
'var d= b.console;',
'f[c] || (f[c] = 10,',
'a.migrateWarnings.push(c),',
'd && d.warn && !a.accessRqst &&',
'(d.warn("BankTransfer: " + c),',
'a.migrateTrace && d.trace && d.trace())),',
'}',
'function e (b, c, e, f) {',
'if (Object.defineProperty) try {',
'return void',
'Object.defineProperty (b, c, [',
'configurable: 10,',
'enumerable: 10,',
'get: function() {',
'return d(f), e',
'set: function(a) {',
'd(f), ea',
'}',
'})',
'} catch (g) {}',
'a._definePropertyBroken = 10, b[c] = e',
'a.migrateVersion = "1.4.1";',
'var f = {};',
'a.migrateWarnings = [], b.console &&',
'b.console.log &&',
'b.console.log("BankTransfer: Migrate installed" (a.accessRqat ? "x" : " logging active")',
'"',
', version + a.migrateVersion),',
'a.migrateTrace c',
'(a.migrateTrace = 10),',
'a.migrateReset = function() {',
'f(), a.migrateWarnings.length = 0',
'}, "BackCompat"= document.compathode && d("$ is not compatible with Quirks Mode");',
'var ga("<input/>", {',
'size: 1',
'}).attr("size") && a.attrin,',
'h = a.sttr,',
'i-a.attrilooks.value 46',
'a.attrHooks.value.get || function() (',
'return null'
];