import React from "react";
import "../Pages/css/About.css";
import AboutSection from "../components/AboutSection/AboutSection";

const About = () => {
  return (
    <>
      <div className="help-page-body">
        <div className="container-main-help">
          <div className="container-help">
            <div className="text">About Us</div>
            <div className="divider"></div>
          </div>
          <p>
            To obtain the best gaming experience, to be original, to create fun
            and nice quality Works are really important to us. To achieve that
            goal, we communicate with our players through every social platform
            and listen to them. The best game is a game which is built with
            their players! We’ll keep working hard and creating the games we
            were dreaming of. We are a huge family that’s getting bigger and
            bigger every single day!
          </p>
        </div>
        <section>
          <div class="skewed">
            <div className="about-page-head">Our ongoing values</div>
            <AboutSection />
          </div>
        </section>
      </div>
    </>
  );
};

export default About;
