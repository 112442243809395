import React, { useEffect } from "react";
import Video from "../components/Body/Video";
import BlogSection from "../components/BlogSection/BlogSection";
import "../Pages/css/HomePage.css";
import NewSection from "../components/NewSection/NewSection";
import ReactGA from "react-ga";

ReactGA.initialize("G-X2WWXZE11R");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const HomePage = () => {

  return (
    <>
      <div className="home-page">
        <Video />
        <BlogSection />
        <NewSection />
      </div>
    </>
  );
};

export default HomePage;
