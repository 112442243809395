import React from "react";
import "../NewSection/NewSection.css";
import bgimg from "../NewSection/bg-img.png";
import { Link } from "react-router-dom";

const NewSection = () => {
  return (
    <>
      <section className="section">
        <div className="container-new-section">
          <div className="left">
            <img src={bgimg} />
          </div>
          <div className="right">
            <h1>What is Hacker Word?</h1>
            <p>
              Hacker Simulator: An Ideal Tool for Anyone Who Wants to Experience
              the Mysterious Atmosphere of the Computer World. Users can feel
              like a real hacker and develop a deep understanding of computer
              systems by using this simulator.
            </p>
            <Link to={"/game"}>
              <button class="btn-clash one">
                <div class="overlay"></div>
                <div class="overlay"></div>
                <div class="overlay"></div>
                <span>START</span>
              </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewSection;
