import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };

  const closeMenu = () => {
    setActive(false);
  };

  // Dışarı tıklamalarda menüyü kapat
  const navRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="Navbar" ref={navRef}>
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <span className="nav-logo">
          <h1>Hacker Simulator</h1>
        </span>
      </Link>

      <div className={`nav-items ${active && "switch"}`}>
        <Link to={"/game"} onClick={closeMenu}>
          Play
        </Link>
        <Link to={"/help"} onClick={closeMenu}>
          Contact
        </Link>
        <Link to={"/about"} onClick={closeMenu}>
          About
        </Link>
        <Link to={"/blogs"} onClick={closeMenu}>
          Blog
        </Link>
        <Link to={"/advertisements"} onClick={closeMenu}>
          Advertisements
        </Link>
      </div>

      <div className={`nav-toggle ${active && "switch"}`} onClick={toggleMenu}>
        <div className="hamburger"></div>
      </div>
    </div>
  );
};

export default Navbar;
