import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import "./App.css";
import HomePage from "./Pages/HomePage";
import { MainLayout } from "./components/Layouts/MainLayout";
import RandomTextAdder from "./Pages/main";
import BruteForceAttack from "./Pages/Blogs/BruteForceAttack";
import MITB from "./Pages/Blogs/MITB";
import Typosquatting from "./Pages/Blogs/Typosquatting";
import Blogs from "./Pages/Blogs";
import Help from "./Pages/Help";
import About from "./Pages/About";
import Advertisements from "./Pages/Advertisements";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import AboutOurAds from "./Pages/AboutOurAds";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/help" element={<Help />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/advertisements" element={<Advertisements />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/about-our-ads" element={<AboutOurAds />} />
          <Route
            path="/blog/brute-force-attack/"
            element={<BruteForceAttack />}
          />
          <Route path="/blog/man-in-the-browser-attack/" element={<MITB />} />
          <Route path="/blog/typosquatting/" element={<Typosquatting />} />
        </Route>
        <Route path="/game" element={<RandomTextAdder />} />
      </Routes>
      <ScrollToTop />
    </div>
  );
}

export default App;
