import React from "react";
import "../Pages/css/Blogs.css";
import AllBlogs from "../components/AllBlogs/AllBlogs";

const Blogs = () => {
  return (
    <>
      <div className="blogs-page-body">
        <div className="blogs-banner-main">
          <h1>All Blogs</h1>
          <p>
            Find the latest content on hacking. Learn about vulnerabilities,
            ethical hacking and more.
          </p>
        </div>
        <AllBlogs/>
      </div>
    </>
  );
};

export default Blogs;
