import React from "react";
import "../Pages/css/Advertisements.css";
import { FaInstagram } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="advertisements-page-body">
        <div className="HW-SUBHEADING">
          <h1>Privacy Policy</h1>
        </div>

        <div className="ads-cont-main">
          <h1>Hacker Simulator Gizlilik Politikası</h1>

          <p>
            Hacker Simulator attaches great importance to user privacy and this
            privacy policy explains how the personal information of users of the
            game is handled and protected.
          </p>

          <h1>Collected Information</h1>
          <p>
            Hacker Simulator does not collect personal information from users.
            Any personal information, such as your name, e-mail address or other
            identifying information, is not knowingly or intentionally collected
            when you use the game.
          </p>

          <h1>Information Use and Sharing</h1>
          <p>
            Because Hacker Simulator does not collect personal information, we
            do not use or share this information. Therefore, we do not share
            user information with third parties.
          </p>

          <h1>Cookies and Similar Technologies</h1>
          <p>
            Hacker Simulator does not use cookies or similar technologies to
            improve the user experience.
          </p>

          <h1>Right to Privacy</h1>
          <p>
            Hacker Simulator users should not be concerned that their personal
            information is not processed in any way. However, if they still have
            questions or concerns about anything, they should not hesitate to
            contact us.
          </p>

          <h1>Policy Updates</h1>
          <p>
            This privacy policy describes the privacy practices of Hacker
            Simulator and may be updated from time to time. We encourage you to
            periodically visit this page to check for updated policies.
          </p>

          <p>
            This policy describes in detail how users' privacy is protected and
            their personal information is not processed.
          </p>
        </div>
        <div className="local-footer-main">
          <div className="local-footer-tools">
            <h1>More</h1>
            <div className="local-footer-icons-container">
              <Link to={"/help"}>
                <FaRegCommentDots className="local-footer-tools-icon" />
              </Link>
              <Link
                to={"/https://www.instagram.com/premadesoft"}
                target="_blank"
              >
                <FaInstagram className="local-footer-tools-icon" />
              </Link>
            </div>
          </div>
          <div className="local-footer-text">
            <Link
              to={"/privacy-policy"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>Privacy Policy</p>
            </Link>

            <Link
              to={"/terms-of-use"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>Terms of Use</p>
            </Link>

            <Link
              to={"/about-our-ads"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>About our Ads</p>
            </Link>
            <p></p>
            <p>
              <span>©</span> 2024 Premadesoft
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
