import React from "react";
import "../Pages/css/Advertisements.css";
import { FaInstagram } from "react-icons/fa";
import { FaRegCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";

const Advertisements = () => {
  return (
    <>
      <div className="advertisements-page-body">
        <div className="HW-SUBHEADING">
          <h1>advertisements</h1>
        </div>

        <div className="ads-cont-main">
          <h1>Welcome</h1>

          <p>
            The advertisements you see on our website are for the purpose of
            supporting our content Provided by Google AdSense. Ads are used to
            promote our content free of charge and provide you with the best
            experience that we can provide.
          </p>

          <p>
            The placement of advertisements is part of our website's
            monetization model. is part of it. However, we would like to point
            out that the display of ads we do not collect or process any
            personal data.
          </p>

          <p>
            We care about user privacy and your data security is our is our
            priority. How Ads processes your personal data For more information
            about our privacy policy, see our privacy policy you can throw away.
            You can also check the content of the ads or adjust your browser
            settings to your personal preferences you can use it.
          </p>

          <p>
            Any questions or feedback about the ads if you have any, please feel
            free to contact us. We are happy to help you we'd be delighted.
          </p>

          <p>Thank you for your visit!</p>
        </div>
        <div className="local-footer-main">
          <div className="local-footer-tools">
            <h1>More</h1>
            <div className="local-footer-icons-container">
              <Link to={"/help"}>
                <FaRegCommentDots className="local-footer-tools-icon" />
              </Link>
              <Link
                to={"/https://www.instagram.com/premadesoft"}
                target="_blank"
              >
                <FaInstagram className="local-footer-tools-icon" />
              </Link>
            </div>
          </div>
          <div className="local-footer-text">
            <Link
              to={"/privacy-policy"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>Privacy Policy</p>
            </Link>

            <Link
              to={"/terms-of-use"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>Terms of Use</p>
            </Link>

            <Link
              to={"/about-our-ads"}
              style={{
                textDecoration: "none",
              }}
            >
              <p>About our Ads</p>
            </Link>
            <p></p>
            <p>
              <span>©</span> 2024 Premadesoft
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advertisements;
