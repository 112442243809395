import React, { useState, useRef } from "react";
import "../AboutSection/AboutSection.css";
import { TfiArrowCircleLeft } from "react-icons/tfi";
import { TfiArrowCircleRight } from "react-icons/tfi";

const AboutSection = () => {
  const containerRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.0;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleScroll = () => {
    const container = containerRef.current;
    const leftArrow = document.getElementById("leftArrow");
    const rightArrow = document.getElementById("rightArrow");
    leftArrow.style.display = container.scrollLeft > 0 ? "block" : "none";
    rightArrow.style.display =
      container.scrollLeft < container.scrollWidth - container.offsetWidth
        ? "block"
        : "none";
  };

  const handleLeftArrowClick = () => {
    containerRef.current.scrollLeft -= 100;
  };

  const handleRightArrowClick = () => {
    containerRef.current.scrollLeft += 100;
  };

  return (
    <>
      <div
        className="container-stream"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onScroll={handleScroll}
      >
        <div className="cards-about">
          <div className="card-stream">
            <div className="card-stream-text">
              <h1>Creativity</h1>
              <p>
                Striving to bring imagination, original ideas, and excitement to
                everything we do.
              </p>
            </div>
          </div>
          <div className="card-stream">
            <div className="card-stream-text">
              <h1>Pioneering</h1>
              <p>
                Acting with the curiosity and courage that it takes to
                experiment,innovate, and lead.
              </p>
            </div>
          </div>
          <div className="card-stream">
            <div className="card-stream-text">
              <h1>Passion</h1>
              <p>
                We are at our best when we pursue what we love, and have fun
                doing it.
              </p>
            </div>
          </div>
          <div className="card-stream">
            <div className="card-stream-text">
              <h1>Determination</h1>
              <p>
                Bringing focus, drive, and conviction to our actions. Thriving
                on the journey, and being motivated to achieve excellence.
              </p>
            </div>
          </div>
          <div className="card-stream">
            <div className="card-stream-text">
              <h1>Learning</h1>
              <p>
                Listening, having humility, being open to new ways of thinking,
                and looking with a lens of inclusion. Challenging ourselves to
                grow and change as a company.
              </p>
            </div>
          </div>
          <div className="card-stream">
            <div className="card-stream-text">
              <h1>Teamwork</h1>
              <p>
                Committed to each other,and to the accountability and integrity
                it takes to be a successful diverse team.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <TfiArrowCircleLeft
          className="arrow left-arrow"
          id="leftArrow"
          onClick={handleLeftArrowClick}
        />
      </div>
      <TfiArrowCircleRight
        className="arrow right-arrow"
        id="rightArrow"
        onClick={handleRightArrowClick}
      />
    </>
  );
};

export default AboutSection;
