import React from "react";
import "../AllBlogs/AllBlogs.css";
import BruteForceAttack from "../AllBlogs/assets/brute-force-attack.png";
import Card2 from "../AllBlogs/assets/card-2.png";
import Trojan from "../AllBlogs/assets/Typosquatting-img.png";
import { Link } from "react-router-dom";

const AllBlogs = () => {
  return (
    <>
      <div className="all-blogs-head">
        <h1>New Blogs</h1>
      </div>
      <div className="container-all-blogs">
        <div className="card-blog-main">
          <Link
            to={"/blog/brute-force-attack/"}
            style={{ textDecoration: "none" }}
          >
            <div className="card-info">
              <img src={BruteForceAttack} />
              <p>Brute force attack</p>
              <span>What is a brute force attack ?</span>
            </div>
          </Link>
        </div>
        <div className="card-blog-main">
          <Link
            to={"/blog/man-in-the-browser-attack/"}
            style={{ textDecoration: "none" }}
          >
            <div className="card-info">
              <img src={Card2} />
              <p>(MitB) Attack</p>
              <span>What is a Man-in-the-Browser (MitB) Attack ?</span>
            </div>
          </Link>
        </div>
        <div className="card-blog-main">
          <Link to={"/blog/typosquatting/"} style={{ textDecoration: "none" }}>
            <div className="card-info">
              <img src={Trojan} />
              <p>Typosquatting</p>
              <span>What is a Typosquatting ?</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AllBlogs;
