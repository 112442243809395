import React, { useState, useEffect, useRef } from "react";
import { randomTexts } from "./tools/randomTexts";
import { metinler } from "./tools/ModalContentContext";
import { remoteConnTexts } from "../Pages/tools/RemoteConnection";
import Draggable from "react-draggable";
import "../Pages/css/main.css";
import { FaFolderClosed } from "react-icons/fa6";
import { FaFolderTree } from "react-icons/fa6";
import { RiFolderShield2Fill } from "react-icons/ri";
import { SiBitcoin } from "react-icons/si";
import InterpolVideo from "../assets/interpol-video.mp4";
import { IoCloseOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const RandomTextAdder = () => {
  const [texts, setTexts] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [fontSize, setFontSize] = useState(16);
  const [colorInput, setColorInput] = useState("");
  const textContainerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdsModal, setIsAdsModalOpen] = useState(true);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [textIndexMain, settextIndexMain] = useState(0);
  const [modalAcik, setModalAcik] = useState(false);
  const [index, setIndexRemoteConn] = useState(0);
  const [yazilanCumleler, setYazilanCumleler] = useState([]);

  const generateRandomPosition = () => ({
    x: Math.floor(Math.random() * (window.innerWidth - 400)),
    y: Math.floor(Math.random() * (window.innerHeight - 200)),
  });

  const [programConsolePosition, setProgramConsolePosition] = useState(
    generateRandomPosition()
  );
  const [remoteConnectionPosition, setRemoteConnectionPosition] = useState(
    generateRandomPosition()
  );
  const [interpolDatabasePosition, setInterpolDatabasePosition] = useState(
    generateRandomPosition()
  );

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (modalAcik) {
        setYazilanCumleler((prevCumleler) => [
          ...prevCumleler,
          remoteConnTexts[index],
        ]);
        setIndexRemoteConn(
          (prevIndex) => (prevIndex + 1) % remoteConnTexts.length
        );
      }
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [modalAcik, index]);

  const modaliAc = () => {
    setModalAcik(!modalAcik);
  };

  {
    /*===== RANDOM TEXT MAIN ===== */
  }

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}

    const handleKeyPress = (e) => {
      addSequentialText();
      scrollToBottom();
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [texts]);

  useEffect(() => {
    const handleResize = () => {
      if (textContainerRef.current) {
        textContainerRef.current.style.height =
          window.innerHeight - textContainerRef.current.offsetTop + "px";
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const addSequentialText = () => {
    if (textIndexMain < randomTexts.length) {
      const newText = randomTexts[textIndexMain];
      settextIndexMain(textIndexMain + 1);
      setTexts((prevTexts) => [...prevTexts, newText]);
    }
  };

  const scrollToBottom = () => {
    textContainerRef.current.scrollTop = textContainerRef.current.scrollHeight;
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  {
    /*===== SETTINGS ===== */
  }

  const openFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  const toggleFullscreen = () => {
    if (!isFullScreen) {
      openFullscreen();
    } else {
      closeFullscreen();
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  const closeHelpModal = () => {
    setIsHelpModalOpen(false);
  };

  const openAdsModal = () => {
    setIsAdsModalOpen(true);
  };

  const closeAdsModal = () => {
    setIsAdsModalOpen(false);
  };

  {
    /*===== PROGRAM CONSOLE ===== */
  }

  const [acik, setAcik] = useState(false);
  const [metinIndex, setMetinIndex] = useState(0);
  const [yazilanMetinler, setYazilanMetinler] = useState([]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (acik) {
        setYazilanMetinler([...yazilanMetinler, metinler[metinIndex]]);
        setMetinIndex((prevIndex) => prevIndex + 1);
      }
    };

    if (acik) {
      document.addEventListener("keypress", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [acik, metinIndex, yazilanMetinler]);

  const ModalProgramConsole = () => {
    setAcik(!acik);
  };

  const clearTexts = () => {
    setTexts([]);
    settextIndexMain(0);

    setYazilanCumleler([]);
    setIndexRemoteConn(0);
    setModalAcik(false); /*Remote Connection*/

    setYazilanMetinler([]);
    setMetinIndex(0);
    setAcik(false); /*Program Console*/
  };

  const [isFooterVisible, setFooterVisible] = useState(true);

  const toggleFooter = () => {
    setFooterVisible(!isFooterVisible);
  };

  return (
    <>
      <div className="container">
        <div
          className="text-container"
          ref={textContainerRef}
          style={{ color: selectedColor, fontSize: `${fontSize}px` }}
          onClick={() => {
            // Metin girişi yapıldığında modal açılmasını engelle
            const input = document.createElement("input");
            input.setAttribute("type", "text");
            input.setAttribute(
              "style",
              "position:absolute; top: -100px; opacity: 0;"
            );
            input.addEventListener("input", () => {
              addSequentialText();
              input.blur();
            });
            document.body.appendChild(input);
            input.focus();
          }}
        >
          {texts.map((textMain, indexMain) => (
            <div key={indexMain} className="text">
              {textMain}
            </div>
          ))}
        </div>

        <div className="icons-container">
          <button
            /*onClick={handleModalToggle}*/
            className="icons-container-btn"
            style={{
              color: selectedColor,
            }}
          >
            <div onClick={ModalProgramConsole} class="icon-container">
              <FaFolderClosed className="btn-icon" />
            </div>
            <div class="text-container-btn">
              <span>Program Console</span>
            </div>
          </button>

          <button
            onClick={modaliAc}
            className="icons-container-btn"
            style={{
              color: selectedColor,
            }}
          >
            <div class="icon-container">
              <FaFolderTree className="btn-icon" />
            </div>
            <div class="text-container-btn">
              <span>Remote Connection</span>
            </div>
          </button>

          <button
            onClick={toggleModal}
            className="icons-container-btn"
            style={{
              color: selectedColor,
            }}
          >
            <div class="icon-container">
              <RiFolderShield2Fill className="btn-icon" />
            </div>
            <div class="text-container-btn">
              <span>Interpol Database</span>
            </div>
          </button>

          <button
            onClick={openAdsModal}
            className="icons-container-btn"
            style={{
              color: selectedColor,
            }}
          >
            <div class="icon-container">
              <SiBitcoin className="btn-icon" />
            </div>
            <div class="text-container-btn">
              <span>Advertisements</span>
            </div>
          </button>
        </div>

        {/*PROGRAM CONSOLE*/}
        {acik && (
          <Draggable
            position={programConsolePosition}
            onDrag={(e, ui) => setProgramConsolePosition({ x: ui.x, y: ui.y })}
            bounds="parent"
          >
            <div
              className="draggable-modal"
              style={{
                backgroundColor: selectedColor,
              }}
            >
              <div
                className="draggable-modal-header"
                style={{
                  backgroundColor: selectedColor,
                }}
              >
                <h2>Program Console</h2>
                <span
                  className="draggable-modal-close"
                  onClick={ModalProgramConsole}
                >
                  &times;
                </span>
              </div>
              <div className="draggable-modal-content">
                {yazilanMetinler.map((metin, idx) => (
                  <p
                    key={idx}
                    style={{
                      color: selectedColor,
                    }}
                  >
                    {metin}
                  </p>
                ))}
              </div>
            </div>
          </Draggable>
        )}

        {modalAcik && (
          <Draggable
            position={remoteConnectionPosition}
            onDrag={(e, ui) =>
              setRemoteConnectionPosition({ x: ui.x, y: ui.y })
            }
            bounds="parent"
          >
            <div
              className="draggable-modal"
              style={{
                backgroundColor: selectedColor,
              }}
            >
              <div
                className="draggable-modal-header"
                style={{
                  backgroundColor: selectedColor,
                }}
              >
                <h2>Remote Connection</h2>
                <span className="draggable-modal-close" onClick={modaliAc}>
                  &times;
                </span>
              </div>
              <div className="draggable-modal-content">
                {yazilanCumleler.map((cumle, idx) => (
                  <p
                    style={{
                      color: selectedColor,
                    }}
                    key={idx}
                  >
                    {cumle}
                  </p>
                ))}
              </div>
            </div>
          </Draggable>
        )}

        {/*is Open*/}
        {isOpen && (
          <Draggable
            position={interpolDatabasePosition}
            onDrag={(e, ui) =>
              setInterpolDatabasePosition({ x: ui.x, y: ui.y })
            }
            bounds="parent"
          >
            <div
              className="draggable-modal"
              style={{
                backgroundColor: selectedColor,
              }}
            >
              <div
                className="draggable-modal-header"
                style={{
                  backgroundColor: selectedColor,
                }}
              >
                <h2>Interpol Database</h2>
                <span className="draggable-modal-close" onClick={toggleModal}>
                  &times;
                </span>
              </div>
              <div className="draggable-modal-content">
                <div className="draggable-modal-video-content">
                  <video src={InterpolVideo} autoPlay loop muted playsInline />
                </div>
              </div>
            </div>
          </Draggable>
        )}

        {isAdsModal && (
          <Draggable>
            <div
              className="draggable-modal"
              style={{
                backgroundColor: selectedColor,
              }}
            >
              <div
                className="draggable-modal-header"
                style={{
                  backgroundColor: selectedColor,
                }}
              >
                <h2>Advertisements</h2>
                <span className="draggable-modal-close" onClick={closeAdsModal}>
                  &times;
                </span>
              </div>
              <div className="draggable-modal-content">
                {/* Google AdSense Code */}
                <ins
                  className="adsbygoogle"
                  style={{ display: "block" }}
                  data-ad-client="ca-pub-5089665458240895"
                  data-ad-slot="7818686123"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins>
                <script>
                  (adsbygoogle = window.adsbygoogle || []).push({});
                </script>
              </div>
            </div>
          </Draggable>
        )}

        {isFooterVisible && (
          <footer className="footer" style={{ borderTopColor: selectedColor }}>
            <div className="">
              <button
                style={{
                  color: selectedColor,
                }}
                className="modal-btn"
                onClick={openModal}
              >
                Settings
              </button>

              <button
                style={{
                  color: selectedColor,
                }}
                className="modal-btn"
                onClick={openHelpModal}
              >
                Help
              </button>
              <Link to={"/Blogs"}>
                <button
                  style={{
                    color: selectedColor,
                  }}
                  className="modal-btn"
                >
                  Blog
                </button>
              </Link>

              <Link to={"/advertisements"}>
                <button
                  style={{
                    color: selectedColor,
                  }}
                  className="modal-btn"
                >
                  Advertisements
                </button>
              </Link>
            </div>
            <div className="footer-close-container">
              <button className="footer-close-btn" onClick={toggleFooter}>
                <IoCloseOutline
                  style={{
                    color: selectedColor,
                  }}
                />
              </button>
            </div>
          </footer>
        )}
      </div>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div
            className="modal-content"
            style={{ borderColor: selectedColor }}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <p className="modal-head" style={{ color: selectedColor }}>
              Settings
            </p>
            <div>
              <div className="settings-row">
                <p style={{ color: selectedColor }}>Color</p>
                <div className="color-buttons">
                  <button
                    className="color-button"
                    style={{ backgroundColor: "#0f0" }}
                    onClick={() => setSelectedColor("#0f0")}
                  ></button>
                  <button
                    className="color-button"
                    style={{ backgroundColor: "red" }}
                    onClick={() => setSelectedColor("red")}
                  ></button>
                  <button
                    className="color-button"
                    style={{ backgroundColor: "blue" }}
                    onClick={() => setSelectedColor("blue")}
                  ></button>
                  <button
                    className="color-button"
                    style={{ backgroundColor: "#9a00ff" }}
                    onClick={() => setSelectedColor("#9a00ff")}
                  ></button>
                  <button
                    className="color-button"
                    style={{ backgroundColor: "#ffff00" }}
                    onClick={() => setSelectedColor("#ffff00")}
                  ></button>
                  <button
                    className="color-button"
                    style={{ backgroundColor: "#ffa500" }}
                    onClick={() => setSelectedColor("#ffa500")}
                  ></button>
                </div>
              </div>
              <div className="settings-row">
                <p style={{ color: selectedColor }}>Font Size</p>
                <input
                  type="range"
                  min="10"
                  max="30"
                  value={fontSize}
                  onChange={(e) => setFontSize(parseInt(e.target.value))}
                  style={{
                    width: "100%",
                    height: "5px",
                    background: `linear-gradient(to right, #0f0 0%, #0f0 ${
                      ((fontSize - 10) / 20) * 100
                    }%, transparent ${
                      ((fontSize - 10) / 20) * 100
                    }%, transparent 100%)`,
                    borderRadius: "5px",
                    outline: "none",
                    appearance: "none",
                    cursor: "pointer",
                  }}
                />
                <input
                  type="number"
                  min="10"
                  max="30"
                  value={fontSize}
                  onChange={(e) => setFontSize(parseInt(e.target.value))}
                  style={{ width: "50px", marginLeft: "10px" }}
                />
              </div>
              <div className="settings-row">
                <p style={{ color: selectedColor }}>Color Code</p>
                <input
                  type="text"
                  value={colorInput}
                  onChange={(e) => {
                    setColorInput(e.target.value);
                    setSelectedColor(e.target.value);
                  }}
                  placeholder="Color Code"
                />
              </div>
              <div className="settings-row">
                <p style={{ color: selectedColor }}>Screen Mode</p>
                <button
                  className="screen-mode-btn"
                  onClick={toggleFullscreen}
                  style={{
                    color: selectedColor,
                    backgroundColor: "#101415",
                  }}
                >
                  {isFullScreen ? "EXIT Full Screen" : "Full Screen"}
                </button>
                <button
                  className="screen-mode-btn"
                  onClick={clearTexts}
                  style={{
                    color: selectedColor,
                    backgroundColor: "#101415",
                  }}
                >
                  Clear
                </button>
                <Link to={"/"}>
                  <button
                    className="screen-mode-btn"
                    style={{
                      color: selectedColor,
                      backgroundColor: "#101415",
                    }}
                  >
                    Exit game
                  </button>
                </Link>
              </div>
              <div className="close-text-modal">
                <p
                  onClick={closeModal}
                  style={{
                    color: selectedColor,
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isHelpModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div
            className="modal-content"
            style={{ borderColor: selectedColor }}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close" onClick={closeHelpModal}>
              &times;
            </span>
            <p className="modal-head" style={{ color: selectedColor }}>
              Help
            </p>
            <div>
              <div className="settings-row">
                <p style={{ color: selectedColor }}>
                  To begin, start typing on your keyboard and your hacker code
                  will immediately appear! You can also enter full screen in
                  your browser.
                </p>
              </div>
              <div className="close-text-modal">
                <p
                  onClick={closeHelpModal}
                  style={{
                    color: selectedColor,
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RandomTextAdder;
