import React from "react";
import "../Footer/Footer.css";
import logo from "../Footer/logo/PremadeStudiosLogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer-main">
        <div className="footer-content">
          <Link to={"/privacy-policy"} style={{textDecoration: "none"}}>
            <p>Privacy Policy</p>
          </Link>

          <Link to={"/terms-of-use"} style={{textDecoration: "none"}}>
            <p>Terms of Use</p>
          </Link>

          <Link to={"/about-our-ads"} style={{textDecoration: "none"}}>
            <p>About our Ads</p>
          </Link>

          <p>© 2024 Premadesoft</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
