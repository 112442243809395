import React from "react";
import "../Body/Video.css";
import videoBg from "../Body/hackingvideo.mp4";
import { Link } from "react-router-dom";

const Video = () => {
  return (
    <>
      <div className="video-container">
        <video autoPlay loop muted playsInline>
          <source src={videoBg} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="text-overlay">
          <p>Hacker Word</p>
          <Link to={"/game"}>
            <button class="btn-clash one">
              <div class="overlay"></div>
              <div class="overlay"></div>
              <div class="overlay"></div>
              <span>START</span>
            </button>
          </Link>
        </div>
      </div>
      {/*MOBILE GORUNUM */}
      <div className="text-container-mobile">
        <h2>HACKER SIMULATOR 24</h2>
        <p className="text-container-mobile-p">
          Hacker Simulator and Typer. Start typing random text on your keyboard
          to simulate that you're hacking a computer.
        </p>
      </div>
    </>
  );
};

export default Video;
