export const remoteConnTexts = [
    "init connection @Server 23.86.111.0",
    "access folder [Top Secret]",
    "override security settings",
    "list admin users >>",
    "Launch auth_crack.exe",
    "activate crack mode",
    "access user [nasaadmin] at [23.86.111.0]",
    "run password crack",
    "init login",
    "user nasaadmin",
    "SELECT customers FROM BankAccounts WHERE balance > $1M",
    "transfer all (balance] to MyAccount",
    "positive",
    "A",
    "[M",
    "CODE [try {",
    "Satement authRequest = conn.crStat();",
    "ResultSet rs = authRequest.exec(loginQuery);",
    "user_id = rs.getInt('GodMode');",
    "if (hashof(request.getParam('password') != -1) )",
    "{ throw BadLoginException();",
    "} else { authTransaction (10,minutes);",
    "}",
    "group_info->blocks[i] = b;",
    "B t",
    "}]",
    "transfer all (balance) to MyAccount",
    "positive",
    "access [balance) @ MyAccount",
    "init offshore transfer",
    "39B t",
    "to",
    "transfer all to ID [43589374ROM] @ Cayman Treasury Bank",
    "[M",
    "'penetrate' = typeof $.accessRequest &&",
    "($.accessRqst = 10),",
    "function(a, b, c) {",
    "function d(c) {",
    "var d= b.console;",
    "f[c] || (f[c] = 10,",
    "a.migrateWarnings.push(c),",
    "d && d.warn && !a.accessRqst &&",
    "(d.warn('BankTransfer: ' + c),",
    "a.migrate Trace && d.trace && d.trace()))",
    "}",
    "function e (b, c, e, f) {",
    "if (Object.defineProperty) try ",
    "return void",
    "Object.defineProperty(b, c, {",
    "configurable: 10,",
    "CO [M",
    "enumerable: 10,",
    "get: function() {",
    "return d(f), e",
    "},",
    "set: function(a) {",
    "d(f), ea",
    "}",
    "39B t",
    "Int",
    "})",
    "} catch (g) {}",
    "a._definePropertyBroken = 10,",
    "b[c] = e",
    "TED STATES OF AMER",
    "}",
  ];
  